import React from 'react';
import config from '../../config';
import logo from '../assets/images/doulakreis-logo.png';
export default function Header() {
  return (
    <div id="header">
      <div className="container small">
        <img className="big-image in-header" src={logo} alt="" />
      </div>
      {/* <span className="logo icon fa-paper-plane-o"></span> */}
      {/* <h1>{config.heading}</h1> */}
      {/* <p>{config.subHeading}</p> */}
    </div>
  );
}
