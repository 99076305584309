import React from 'react';

import Layout from '../components/Layout';

import { Link } from 'gatsby';
import Header from '../components/Header';
import Footer from '../components/Footer';

import ninaPortrait from '../assets/images/nina_portrait.jpg';
import helenPortrait from '../assets/images/helen_portrait.jpg';
import marlenPortrait from '../assets/images/marlen_portrait.jpg';
import luciaPortrait from '../assets/images/lucia_portrait.jpg';
import celinePortrait from '../assets/images/celine_portrait.jpg';
import angiePortrait from '../assets/images/angie_portrait.jpg';
import doulas from '../assets/images/all_doulas.jpg';

const IndexPage = () => (
  <Layout>
    <Header />

    <div id="main">
      <header className="major container large">
        <h2>
        Wer sind wir?
        </h2>
        <p>
        Wir sind sechs Frauen, die zusammen die Ausbildung zur Doula absolviert und im Sommer 2021 abgeschlossen haben.
        Wir sind alle Mitglied im Berufsverband Doula Schweiz.
        Uns liegt am Herzen, Frauen und Paare während der Zeit von Schwangerschaft, Geburt und Wochenbett zur Seite zu stehen und sie in ihrer Selbstbestimmung zu stärken.
        Im doulakreis bilden wir uns gemeinsam weiter und bieten gegenseitige Unterstützung in unserer Tätigkeit.
        <br />
        Wir sind in stetigem Austausch und können so kompetente Begleitungen im erweiterten Raum Zentralschweiz anbieten.
        <br />
        Gemeinsam möchten wir uns Doulas sichtbar machen und Frauen wissen lassen, dass es uns gibt.

        </p>
      </header>
      <header className="major container large">
      <img className="big-image in-body" src={doulas} alt="" />
      </header>
      <header className="major container large">
        <h2>
        Was ist eine Doula?
        </h2>
        <p>
          Durch Gespräche, die Begleitung während der Geburt und das Dasein im Wochenbett schenkt eine Doula emotionale Sicherheit und spannt einen Bogen über diese ereignisreiche Zeit. Das Geburtserlebnis der ganzen Familie wird dadurch positiv beeinflusst.
          <br />
          Eine Doula ersetzt weder Hebamme noch Arzt oder Ärztin und übernimmt keine medizinische Funktion. Somit kann sie sich ganz auf die Frau/das Paar einlassen und bleibt an derer/n Seite.
          <br />
          Dank eigenen Geburtserfahrungen und der fundierten Ausbildung kennt und versteht sie die Bedürfnisse werdender Eltern.
          <br />
        </p>
      </header>

      <header className="major container large">
        <h2>Die Unterstützung einer Doula kann…</h2>
        <p>
          …Geburtsdauer und Wehenzeit verkürzen.
          <br />
          …Spontangeburten begünstigen.
          <br />
          …den Einsatz wehenfördernder Mitteln verringern.
          <br />
          …den Einsatz von Saugglocke und Zange allenfalls verhindern.
          <br />
          …einen Kaiserschnitt allenfalls verhindern.
          <br />
          …den Bedarf an Schmerzmitteln und PDA verringern.
          <br />
          …die Paarbeziehung stärken.
          <br />
        </p>
      </header>

      <div className="box alt container">
        <section className="feature left">
          <a href="/#" className="image" style={{ "pointerEvents": "none"}}>
            <img src={angiePortrait} alt="" />
          </a>
          <div className="content">
            <h3>Angela Stocker</h3>
            <p>
            <a target="_blank" href="https://www.womankind.ch" >womankind.ch</a> <br />
            Wohnort : Dürrenroth (BE) <br />
            Sprache: Deutsch, English
            </p>
          </div>
        </section>
        <section className="feature right">
          <a href="/#" className="image" style={{ "pointerEvents": "none"}}>
            <img src={celinePortrait} alt="" />
          </a>
          <div className="content">
            <h3>Céline Tanner</h3>
            <p>
            <a target="_blank" href="https://www.floweroflove.ch" >floweroflove.ch</a> <br />
            Wohnort : Schachen (LU) <br />
            Sprache: Deutsch
            </p>
          </div>
        </section>
        <section className="feature left">
          <a href="/#" className="image" style={{ "pointerEvents": "none"}}>
            <img src={luciaPortrait} alt="" />
          </a>
          <div className="content">
            <h3>Lucia Dähler</h3>
            <p>
            <a target="_blank" href="https://www.luciadaehler.ch/" >luciadaehler.ch</a> <br />
            Wohnort : Stadt Luzern <br />
            Sprache: Deutsch, Svenska, English
            </p>
          </div>
        </section>
        <section className="feature right">
          <a href="/#" className="image" style={{ "pointerEvents": "none"}}>
            <img src={ninaPortrait} alt="" />
          </a>
          <div className="content">
            <h3>Nina Barahona</h3>
            <p>
            <a target="_blank" href="https://www.doulavida.ch" >doulavida.ch</a> <br />
            Wohnort : Sachseln (OW) <br />
            Sprache: Deutsch, English, Español
            </p>
          </div>
        </section>
        <section className="feature left">
          <a href="/#" className="image" style={{ "pointerEvents": "none"}}>
            <img src={helenPortrait} alt="" />
          </a>
          <div className="content">
            <h3>Helene Bütler-Fessler</h3>
            <p>
            <a target="_blank" href="http://moyo-doula.ch" >moyo-doula.ch</a> <br />
            Wohnort : Hitzkirch (LU) <br />
            Sprache: Deutsch
            </p>
          </div>
        </section>
        <section className="feature right">
          <a href="/#" className="image" style={{ "pointerEvents": "none"}}>
            <img src={marlenPortrait} alt="" />
          </a>
          <div className="content">
            <h3>Marlen Wüest</h3>
            <p>
            <a target="_blank" href="https://www.doulahaerzchlopfe.ch" >doulahaerzchlopfe.ch</a> <br />
            Wohnort : Kriens (LU) <br />
            Sprache: Deutsch
            </p>
          </div>
        </section>
      </div>

      <footer>
        <br />
        <br />
        <br />
        <br />
        {/* <h3>Get shady with this starter</h3>
        <p>
          There is also elements page in this template. Click below button to
          check
        </p>
        <ul className="actions special">
          <li>
            <Link to="/Elements" className="button">
              View Elements
            </Link>
          </li>
        </ul> */}
      </footer>
    </div>
    <Footer />
  </Layout>
);

export default IndexPage;
