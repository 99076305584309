module.exports = {
  siteTitle: 'DoulaKreis', // <title>
  manifestName: 'directive',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-directive/`, // This path is subpath of your hosting https://domain/portfolio
  heading: '',
  subHeading: 'gebären, unterstützen, vernetzen',
  // social
  socialLinks: [
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://instagram.com/doulakreis',
    },
    // {
    //   icon: 'fa-twitter',
    //   name: 'Twitter',
    //   url: 'https://twitter.com/onlyanubhav',
    // },
    // {
    //   icon: 'fa-facebook',
    //   name: 'Facebook',
    //   url: 'https://facebook.com/theanubhav',
    // },
    // {
    //   icon: 'fa-envelope-o',
    //   name: 'Email',
    //   url: 'mailto:anubhav.srivastava00@gmail.com',
    // },
  ],
};
